<template>
  <component
    :is="getInput(field.type)"
    v-model="model[field.name]"
    v-bind="field.attrs"
    v-on="field.on">
    <template v-for="fieldSlot in field.slot" v-slot:[`${fieldSlot.name}`]="slotProps">
      <slot :name="`${fieldSlot.name}`" v-bind="slotProps"></slot>
    </template>
  </component>
</template>

<script>
import {
  VTextField,
  VSelect,
  VAutocomplete,
  VDatePicker,
  VCombobox,
  VRadio,
  VCheckbox,
  VSwitch,
} from 'vuetify/lib';

export default {
  name: "DefaultComponent",

  props: {
    field: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getInput(type) {
      switch (type) {
        case 'select':
          return VSelect;
        case 'combobox':
          return VCombobox;
        case 'textfield':
          return VTextField;
        case 'autocomplete':
          return VAutocomplete;
        case 'checkbox':
          return VCheckbox;
        case 'radio':
          return VRadio;
        case 'datepicker':
          return VDatePicker;
        case 'switch':
          return VSwitch;
        default:
          return VTextField;
      }
    },
  },
}
</script>