<template>
  <v-row
    justify="end">
    <v-col
      sm="12"
      md="12"
      lg="5"
      xl="5"
      cols="12"
      :class="!$vuetify.breakpoint.mdAndDown ? null : defaultColsClass">
      <v-btn
        :color="defaultColor"
        :loading="loading"
        @click="$emit('search')"
        style="width: 100%">
        {{ defaultSearchLabel }}
      </v-btn>
    </v-col>

    <v-col
      sm="12"
      md="12"
      lg="5"
      xl="5"
      cols="12"
      :class="!$vuetify.breakpoint.mdAndDown ? null : defaultColsClass">
      <v-btn
        text
        outlined
        :color="defaultColor"
        :disabled="loading"
        style="width: 100%"
        @click="$emit('clearFilters')">
        {{ defaultClearLabel }}
      </v-btn>
    </v-col>

    <v-col
      sm="12"
      md="12"
      lg="2"
      xl="2"
      cols="12"
      :class="`d-flex justify-center ${!$vuetify.breakpoint.mdAndDown ? null : defaultColsClass}`">
      <v-btn
        icon
        @click="$emit('expand')">
        <v-icon
          class="onClickEffect elevation-0"
          :style="syncIconTransition"
          color="#757575">
          fas fa-chevron-up
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FilterActions",

  data: () => ({
    defaultColsClass: {
      'px-6': true,
    }
  }),

  props: {
    syncIconTransition: {
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    defaultColor: {
      type: String,
      required: true,
    },
    defaultSearchLabel: {
      type: String,
      required: true,
    },
    defaultClearLabel: {
      type: String,
      required: true,
    }
  },

  methods: {},

  computed: {}
}
</script>