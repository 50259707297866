<template>
  <v-container
    fluid>
    <v-data-table
      v-model="localModel"
      v-bind="tableConfig"
      v-on="tableConfig.on"
      :page.sync="page"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :loading-text="loadingMessage"
      class="elevation-1"
      :height="!!items && items.length >= 10 ? 528 : null"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions,
        disablePagination: loading,
      }">
      <template v-slot:no-data>
        <slot name="no-data"></slot>
      </template>

      <template v-for="header in tableConfig.headers" v-slot:[`item.${header.value}`]="slotProps">
        <slot :name="`item.${header.value}`" v-bind="slotProps"></slot>
      </template>
    </v-data-table>
</v-container>
</template>

<script>
export default {
  name: "DefaultTable",

  data: () => ({
    page: 1,
    itemsPerPage: 25,
    localModel: [],
  }),

  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [ 25, 50, 75, 100 ],
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando..."
    },
  },

  watch: {
    localModel: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateModel', [...newVal]);
      },
    },
  },
}
</script>