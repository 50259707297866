<template>
  <v-container fluid class="px-5">
    <ViewTitles class="mx-2">
      <h2
        class="primary--text"
        slot="title">
        Automação de Relatórios Cliente
      </h2>
    </ViewTitles>

    <DefaultFilter
      :refFilter="'ReportAutomationContentFilter'"
      :model="filterReportAutomation"
      :filters="filters"
      :loading="loading"
      @search="handleReportAutomation"
    />

    <v-row
      class="mt-2 mb-0">
      <v-col class="d-flex justify-end">
				<v-btn
					:disabled="loading"
					color="textPrimary"
					class="white--text"
					x-large
					@click="createNewConfiguration()">
					<v-icon>
						mdi-plus
					</v-icon>
					Nova Configuração
				</v-btn>
			</v-col>
    </v-row>

    <v-row
      class="mt-0">
      <DefaultTable
        ref="ReportAutomationTable"
        :items="reportAutomations"
        :tableConfig="tableConfig"
        :loading="loading">
        <template v-slot:no-data>
          <span>
            Nenhum dado encontrado.
          </span>
        </template>

        <template v-slot:[`item.financialGroupId`]="{ item }">
          {{ (getFinancialGroupNameById(item.financialGroupId)) }}
        </template>

        <template v-slot:[`item.dataModel`]="{ item }">
          {{ getDataModelType(item.dataModel) || "-" }}
        </template>

        <template v-slot:[`item.reportLayout`]="{ item }">
          {{ getReportLayoutType(item.reportLayout) }}
        </template>

        <template v-slot:[`item.reportQueryType`]="{ item }">
          <!-- {{ item.reportQueryType || "-" }} -->
          Movimentações criadas nos últimos 30 dias por Grupo Financeiro
        </template>

        <template v-slot:[`item.reportIntegrationType`]="{ item }">
          {{ getIntegrationType(item.reportIntegrationType) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="textPrimary"
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>

            <TodoListActions
              :actions="getTodoActions(item)"
            />
          </v-menu>
        </template>
    </DefaultTable>
    </v-row>

  <v-footer class="mt-5">
    <v-row class="d-flex justify-end">
      <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="pr-0">
        <v-btn
          x-large
          block
          outlined
          color="textPrimary"
          @click="onClickBack()">
          Voltar
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
  </v-container>
</template>

<script>

import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin'
import ReportAutomationMixin from '@/shared/mixins/reportAutomation/reportAutomationMixin';
import MyReportsMixin from '@/shared/mixins/myReports/myReportsMixin';

import CustomFilter from '@/components/CustomFilter/CustomFilter.vue';
import ViewTitles from '@/components/PageTitles/ViewTitles.vue';

import CustomerReportAutomationService from "@/services-http/sdi/CustomerReportAutomationService.js";

import TodoListActions from '@/components/TodoList/TodoListActions.vue';

import DefaultFilter from '@/components/Default/DefaultFilter/DefaultFilter.vue';
import DefaultTable from '@/components/Default/DefaultTable/DefaultTable.vue';
import { mapMutations } from 'vuex';

export default {
  components: {
    ViewTitles,
    CustomFilter,
    DefaultFilter,
    DefaultTable,
    TodoListActions,
  },

  data: () => ({
    reportAutomationService: null,
    reportAutomations: [],
    loading: false,
    autoCompleteProps: {
			placeholder: "Selecione",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			clearable: true,
      hideDetails: true,
			dense: true,
      color: "textPrimary",
      itemColor: "textPrimary",
		},
    colsProps: {
      cols:"12",
      xl:"4",
      lg:"4",
      md:"4",
      sm:"6",
    },
    isRHProtegido: false,

    filterReportAutomation: {},

    financialGroups: [],
  }),

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    GenericFunctionsMixin,
    ReportAutomationMixin,
    MyReportsMixin,
  ],

  methods: {
    ...mapMutations({
      setFormSelected: 'customer-report-automation/setFormSelected'
    }),

    // return to the route that has all the menus
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },

    // set object to filter page data
    handleReportAutomation() {
      let params = {
        ...this.filterReportAutomation,
        receivingDateStart: this.filterReportAutomation.dates[0],
        receivingDateEnd: this.filterReportAutomation.dates[1],
      }

      delete params.dates;

      this.getReportAutomations(params)
    },

    async getReportAutomations (params) {
			this.loading = true;
			this.page = 1
			const filteredParams = !!params ? this.getFilteredParams(params) : {};
			const paramsRequest = this.buildSearchParams(filteredParams, "search");
			await this.reportAutomationService.GetReportAutomations(paramsRequest).then((response) => {
				this.reportAutomations = response.data.content;
			});
			this.loading = false;
		},

    getTodoActions(item) {
      const actions = [
        {
          label: 'Editar',
          icon: 'fas fa-pencil-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.editCustomReport(item),
        },
        {
          label: 'Excluir',
          icon: 'fa fa-trash-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.inactiveCustomReport(item),
        },
      ];

      return actions;
    },

		editCustomReport(item) {
			this.setFormSelected(item);
			this.redirectRouter('CustomerReportAutomationConfiguration',
			{
				actionType: 'update',
				id: item.id,
				isRHProtegido: this.isRHProtegido,
			});
		},

		async inactiveCustomReport(item) {
			item.active = false;
			try {
				await this.reportAutomationService.InactiveForm(item.id);
				this.$refs.SnackbarCustomize.open('success', 'Automação inativada com sucesso.');
			} catch(err) {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao excluir a automação.');
			} finally {
				await this.getReportAutomations();
			}
		},

    createNewConfiguration() {
			this.redirectRouter('CustomerReportAutomationConfiguration',
				{
					isRHProtegido: this.isRHProtegido,
					actionType: 'insert'
				});
		},
  },

  computed: {
    filters() {
      return [
        {
          name: 'financialGroupId',
          type: 'autocomplete',
          col: {
            attrs: this.colsProps,
          },
          attrs: {
            label: 'Grupo Financeiro',
            items: this.financialGroups,
            ...this.autoCompleteProps,
          }
        },
        {
          name: 'dataModel',
          type: 'autocomplete',
          col: {
            attrs: this.colsProps,
          },
          attrs: {
            label: 'Modelo de Dados',
            items: this.getDataModelTypes,
            ...this.autoCompleteProps,
          }
        },
        {
          name: 'dataModel',
          type: 'autocomplete',
          col: {
            attrs: this.colsProps,
          },
          attrs: {
            label: 'Layout de Relatório',
            items: this.getReportLayoutTypesFilter,
            ...this.autoCompleteProps,
          }
        },
        {
          name: 'reportIntegrationType',
          type: 'autocomplete',
          col: {
            attrs: this.colsProps,
          },
          attrs: {
            label: 'Tipo de Integração',
            items: this.getIntegrationTypes,
            ...this.autoCompleteProps,
          }
        },
      ]
    },

    tableConfig() {
      return {
        headers: [
          {
            text: "Grupo Financeiro",
            value: "financialGroupId",
            align: "start",
            class: "text--truncate",
          },
          {
            text: "Modelo de Dados",
            value: "dataModel",
            align: "start",
            class: "text--truncate",
          },
          {
            text: "Layout de Relatório",
            value: "reportLayout",
            align: "start",
            class: "text--truncate",
          },
          {
            text: "Nome de Consulta",
            value: "reportQueryType",
            align: "start",
            class: "text--truncate",
          },
          {
            text: "Tipo de Integração",
            value: "reportIntegrationType",
            align: "start",
            class: "text--truncate ",
          },
          {
            text: "Ações",
            value: "actions",
            align: "start",
            class: "text--truncate",
            sortable: false
          }
        ]
      }
    },
  },

  async created() {
    this.reportAutomationService = new CustomerReportAutomationService();
    await this.getFinancialGroups();

    this.isRHProtegido = this.$route.query.isRHProtegido;
  },

  async mounted() {
    await this.getReportAutomations();
  },
};
</script>
